header {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    position: absolute;
    z-index: 2;
    padding: 4rem 4rem 0 4rem;

    @media all and (max-width: 767px) {
        width: 100%;
        padding: 4rem 2rem 0 2rem;
    }
}

nav {
    display: flex;
    @media all and (max-width: 767px) {
        display: none;
    }
}

nav ul {
    display: flex;

    li:not(:first-of-type) {
        margin-left: 4rem;
    }
}

.hamburger {
    @media all and (min-width: 768px) {
        display: none;
    }
}