.contact {
}

.contact__section {
    @media all and (min-width: 768px) {
        width: 50%;

        &:first-of-type {
            padding-right: 20px;
        }

        &:last-of-type {
            padding-left: 20px;
        }
    }
}

.contact__section__row {
    @media all and (min-width: 768px) {
        display: flex;
    }

    & > div {

        @media all and (max-width: 767px) {
            padding-bottom: 20px;
        }

        @media all and (min-width: 768px) {
            width: 50%;
    
            &:first-of-type {
                text-align: left;
            }
    
            &:last-of-type {
                text-align: right;
            }
        }
    }
}

.map {
    @media all and (max-width: 767px) {
        padding-bottom: 20px;
    }
}

form {
    margin-top: 4rem;
}

button {
    margin-top: 2rem;
}

.map iframe {
    width: 100%;
}

.error-message {
    color: darkred;
    text-align: right;
}

.sent-message {
    text-align: right;
}

// .map iframe,   
// .map object,  
// .map embed {
//     position: absolute;
//     top: 0;
//     left: 0;
//     width: 100%;
//     height: 100%;
// }