.navigation-items {
    font-size: 1.4rem;

    @media (min-width: 768px) {
        width: calc(645px - 6rem - 8px);
    }
    
    a {
        color: #8a8a8a;
        padding: 0 0 2px 0;
        border-bottom: 1px solid transparent;
        transition: border .25s ease-out;
        line-height: 1.6rem;

        &.active {
            color: #000;
            border-color: #222;
            transition: border .25s ease-in;
        }

        &:hover {
            color: #000;
        }
    }

    &.navigation-items--sub-navigation {
        display: flex;
        justify-content: flex-end;

        li:not(:first-of-type) {
            margin-left: 4rem;
        }

        @media (max-width: 767px) {
            flex-direction: column;
            align-items: flex-end;
            padding-bottom: 4rem;
        }
    }
}