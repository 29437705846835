footer {
    width: 100%;
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    background-color: #002027;
    color: white;
    text-transform: uppercase;
    font-size: 1.2rem;
    align-items: center;
    padding: 2rem;

    @media all and (min-width: 768px) {
        position: fixed;
        bottom: 0;
        padding: 0 4rem;
        height: 3rem;
    }

    @media all and (max-width: 767px) {
        flex-direction: column-reverse;
    }

    .footer__center {
        letter-spacing: 1.5px;
        @media all and (max-width: 767px) {
            margin-bottom: 20px;
        }
    }

    .footer__aside {
        display: flex;

        @media all and (min-width: 768px) {
            flex-basis: 140px;
        }
    }

    a {
        color: white;
        display: flex;
    }
}

.footer__aside--right {
    justify-content: flex-end;
    align-items: center;
    min-width: 140px;

    @media all and (max-width: 767px) {
        margin-bottom: 20px;
    }

    span {
        margin-right: 4px;
    }
}