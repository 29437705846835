@font-face {
    font-family: 'Bahnschrift Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Bahnschrift Regular'), url('./fonts/BAHNSCHRIFT.TTF') format('woff');
}

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

html {
    font-size: 62.5%;
}

body {
	font-family: 'Karla', "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1.5rem;
    font-family: 'Bahnschrift Regular';
}

p:not(:first-of-type) {
    margin: 1rem 0 0 0;
}

ul {
    list-style: none;

    &.circle {
        // list-style-type: circle;
        padding-left: 19px;

        li::before {
            content: "\2022";
            display: inline-block; 
            width: 12px;
            margin-left: -18px;
            font-weight: bold;
        }
    }

    &.sub-navigation {
        display: flex;
    }
}

p, li {
    line-height: 1.6em;
}

p:not(:last-of-type) {
    padding-bottom: 1rem;
}

a {
    text-decoration: none;
    cursor: pointer;
    color: inherit;
    color: #8a8a8a;
}

input[type="email"], input[type="text"], textarea {
    width: 100%;
    padding: 12px;
    margin: 6px 0 1rem;
    border: 1px solid #ccc;
    background: #fafafa;
}

.dos-casitas {
    @media (min-width: 768px) {
        height: 100vh;
        background-size: cover;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}

.carousel {
    @media (max-width: 767px) {
        display: none;
    }
}

form .two-in-line {
    display: flex;

    & > *:first-of-type {
        margin-right: 2rem;
    }
}

form .two-in-line .field {
    display: flex;
    flex-direction: column;
}

label {
    font-size: 1.4rem;
}

button {
    background-color: 
#f7f7f7;
padding: 1em 2.5em;
border: none;
// color: #8a8a8a;
    text-transform: uppercase;
    font-weight: 700;
    -webkit-transition: color .25s ease-in;
    transition: color .25s ease-in;
    cursor: pointer;
    margin-left: auto;
    margin-right: 0;
    display: block;
}

button:hover {
    color: 
    #000;
}

.carousel .control-dots {
    z-index: 2;
    bottom: 30px !important;
    left: 50%;
    transform: translateX(-50%);
    width: unset;
}

.carousel .slide .legend {
    width: unset;
    background: unset;
    font-size: unset;
    text-transform: uppercase;
    border: unset;
    padding: unset;
    left: 4rem;
    bottom: 4rem;
    text-align: unset;
    opacity: unset;
    margin-left: unset;
    mix-blend-mode: difference;
}

.carousel .slide img {
    background-position: center;
}

@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}