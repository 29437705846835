main {
    // background-color: rgba(255, 255, 255, 0.8);
    padding-top: 4rem;
    transition: transform .5s ease-out;
    display: flex;
    flex-direction: column;
    padding-bottom: 40px;
    width: 100%;

    @media all and (min-width: 768px) {
        position: fixed;
        right: 0;
        height: 100%;
        padding-left: 4rem;
        padding-right: 4rem;
    }

    @media all and (max-width: 767px) {
        padding-top: 10rem;
        padding-left: 2rem;
        padding-right: 2rem;
    }

    &.main--narrow {
        @media all and (min-width: 768px) {
            width: 645px;
            // border-left: solid 8px white;
            border-left: solid 8px rgba(255, 255, 255, 0.0);
            padding-left: 2rem;

            nav {
                justify-content: flex-start;
            }
        }
    }

    section {
        animation: appear 1s;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }
}

nav {
    justify-content: flex-end;
    margin-bottom: 6rem;
}

.hide-main-button__wrapper {
    display: flex;
    // padding: .4rem 1rem .4rem 0;
}

.hide-main-button {
    padding: .8rem 1rem 0 0;
}

.hide-main-button, .show-main-button {
    display: flex;
    align-items: center;
    cursor: pointer;
    text-transform: uppercase;
    font-size: 12px;

    @media all and (max-width: 767px) {
        display: none;
    }

    *:first-child {
        margin-right: 4px;
    }
}

.show-main-button {
    position: fixed;
    bottom: 4rem;
    right: 1rem;
    transition: transform .5s ease-out;
    width: 90px;
    right: -90px;
    padding: .6rem 1.2rem;
    background-color: #f7f7f7;
    // transform: translateX(calc(100% + 1rem));
    
    // &.show-main-button--show {
    //     transform: translateX(-90px) translateX(-1rem);
    // }
}