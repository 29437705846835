.video-wrapper {
    height: 100vh;
    display: flex;
    align-items: center;
    background-color: #000;;
}

video {
    width: 100%;
    height: 100%;
}

.skip-button {
    position: fixed;
    bottom: 20px;
    right: 20px;
    padding: .6rem 1.2rem;
    background-color: #f7f7f7;
    cursor: pointer;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    font-size: 12px;
    z-index: 9;

    *:first-of-type {
        margin-right: 4px;
    }
}