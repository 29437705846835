img {
    display: block;
    margin: auto;
}

.home {
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    overflow: auto;
}

.home__img {
    min-height: 120px;
    margin-top: 0rem;
    margin-bottom: 4rem;
}