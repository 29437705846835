.about {
    height: calc(100% - 30px);
    display: flex;
    flex-direction: column;
    overflow: auto;

    @media all and (min-width: 768px) {
        flex-direction: column-reverse;
    }
}

.about__wrapper {
    @media all and (min-width: 768px) {
        height: 100%;
        overflow: auto;
    }

    div {
        animation: appear 1s;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
    }
}

.navigation-items--sub-navigation {
    width: 100%;
}