.dos-casitas--light-background {
    background-color: rgba(255, 255, 255, 0.3);
}

.dos-casitas {
    
}

.dos-casitas__carousel, 
.dos-casitas__background {
    animation: appear 1s;
    opacity: 0;
    animation-fill-mode: forwards;
    animation-timing-function: linear;
}

.dos-casitas__background {
    background-size: cover;
    height: 100vh;
    background-position: center;

    @media (max-width: 767px) {
        display: none;
    }

    div {
        position: absolute;
        left: 4rem;
        bottom: 4rem;
        mix-blend-mode: difference;
        color: #fff;
        text-transform: uppercase;
        line-height: 1.6em;
        font-size: 1.4rem;
    }
}