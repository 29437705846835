// .section__container {
//     @media all and (min-width: 768px) {
//         height: 100%;
//     }
// }

// .realizations-top {
//     @media all and (min-width: 768px) {
//         margin-right: calc(4rem + 70px);
//     }
// }

.realizations .gallery {
    display: flex;
    flex-grow: 1;

    @media all and (max-width: 767px) {
        flex-direction: column;
    }
}

.realizations .gallery.gallery--das-haus div, .realizations .gallery.gallery--talo div {
    @media all and (min-width: 768px) {
        width: 33.3%;
    }
}

.realizations .gallery div {
    height: auto;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;

    @media all and (max-width: 767px) {
        height: 200px;

        &:not(:last-of-type) {
            margin-bottom: 20px;
        }
    }

    @media all and (min-width: 768px) {
        width: 25%;

        &:first-of-type {
            width: 50%;
        }
    }
}

// img.img--in-text.img--top-and-left {
//     margin: 1rem 4rem 1rem 0;
// }
// img.img--in-text {
//     float: left;
//     margin: 4rem;
// }
// img.img--in-text {
//     margin-bottom: 2rem;
// }
// img {
//     max-width: 100%;
// }

// .realizations-navigation {
//     -webkit-box-orient: vertical;
// -webkit-box-direction: normal;
// -ms-flex-direction: column;
// flex-direction: column;
// padding-bottom: 4rem;
// z-index: 1;

//     @media all and (min-width: 768px) {
//         position: absolute;
//         top: 0;
//         right: 0;
//     }
// }

.realizations {
    display: flex;
    flex-direction: column;

    @media all and (min-width: 768px) {
        height: 100%;
    }
}

.realizations__wrapper {
    display: flex;
    flex-direction: row-reverse;

    @media all and (max-width: 767px) {
        flex-direction: column;
    }
}

.realizations__navigation {
    width: 80px;

    @media all and (max-width: 767px) {
        margin-bottom: 20px;
    }
}

.realizations__top {
    @media all and (min-width: 768px) {
        width: calc(100% - 80px);
    }
}

.realizations__logo {
    min-height: 120px;
// margin-top: 0rem;
margin-bottom: 4rem;
}