.side-menu {
    position: fixed;
    width: 280px;
    max-width: 70%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 200;
    background-color: white;
    padding: 32px 16px;
    box-sizing: border-box;
    transition: transform .3s ease-out;
    background-color: #343a40;

    @media (min-width: 768px) {
        display: none;
    }

    &.side-menu--open {
        transform: translateX(0);
    }
    
    &.side-menu--close {
        transform: translateX(-100%);
    }

    a {
        color: rgba(255, 255, 255, 0.5);
        line-height: 4rem;

        &:hover {
            color: white;
        }

        &.active {
            color: white;
            border-color: white;
        }
    }
}