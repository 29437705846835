.apartments {
    display: flex;
    // height: 100%;
    height: calc(100% - 82.4px);

    @media all and (max-width: 767px) {
        flex-direction: column-reverse;
    }

    img {
        width: calc(100% - 40px);
        max-width: 100%;
        animation: appear 1s;
        opacity: 0;
        animation-fill-mode: forwards;
        animation-timing-function: linear;
        
        @media all and (min-width: 768px) {
            width: auto;
            max-height: 98%;
        }
    }

    & > div {
        @media all and (min-width: 768px) {
            width: calc(100% - 200px);
        }
    }

    .navigation-items {
        text-align: right;

        @media all and (min-width: 768px) {
            width: 200px;
            text-align: right;
        }

        @media all and (max-width: 767px) {
            margin-bottom: 20px;
        }
    }
}